<template>
  <img v-if="!src" v-bind="$props" />
  <img v-else-if="src.endsWith('.svg')" v-bind="$props" />
  <NuxtImg
    v-else
    v-bind="{
      format: 'webp',
      sizes: '100vw sm:320px md:640px lg:768px xl:1024px xxl:1280px 2xl:1536px',
      loading: 'lazy',
      ...$props,
    }"
  />
</template>
<script setup lang="ts">
const props = defineProps<{
  src: string;
  [x: string]: any;
}>();

if (!props.src) {
  useElasticApm()?.captureError({
    name: 'Image Error',
    message: 'Image Source Missing at ' + useRoute().fullPath,
  });
}
</script>
